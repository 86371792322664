@import './variables';

body {
    max-width: 1580px;
    margin: auto;
  //  background: black;
  //  color: #efefef;
    font-family: ProximaNova, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: $font-family-pr;
  }

.page-screen {
    opacity: 0;
    transition: opacity .1s linear;
    transition-delay: .25s;
}

.page-screen-visible {
    opacity: 1;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#navbar-header {
    text-align: center;
}

#navbar {
    padding-top: 8px;
}

.nav-desktop {
    margin: auto;
    padding: 0px 16px;
}

#navbar a {
    color: white;
    &:hover  {
        color: #333;
        text-decoration: none;
    }
}

.navbar-fixed-top.top-nav-collapse .logo, .mobile-logo {
    height: 44px;
    position: relative;
    top: 3px;
    left: 1px;
    border-radius:10px;
}

.navbar-margin {
    margin-top: 60px;
}

.mobile {
    display:none;
}

.mobile-logo {
    top: 6px;
}

.icon-0deg {
    transform: rotate(0deg);
        /* Firefox */
	-moz-transition: all 1s ease;
	/* WebKit */
	-webkit-transition: all 1s ease;
	/* Opera */
	-o-transition: all 1s ease;
	/* Standard */
	transition: all 1s ease;
}

.icon-180deg {
    transform: rotate(180deg);
    /* Firefox */
-moz-transition: all 1s ease;
/* WebKit */
-webkit-transition: all 1s ease;
/* Opera */
-o-transition: all 1s ease;
/* Standard */
transition: all 1s ease;
}

.logo {
    border: none;
}
#mobile-nav {
    position: relative;
    top: 5px;
    left: 20px;
}

.mobile-logo {
    position: absolute;
    left: 8px;
}

.mobile-product-name {
    display: inline-block;
    color: white;
    font-size: 22px;
    padding-top: 16px;
    text-transform: none;
}

.mobile-brand {
    display:none;
}


@media (max-width: 768px) {
 .mobile-nav, .mobile-brand {
    display: inline-block;
    text-align: center;
}

}

#mobile-nav {
    display: none;
}

.navbar-brand {
       display: inline-block;
       white-space: nowrap; 
    }

.navbar-brand a {
    color: green;
}
.navbar-brand:focus, .navbar-brand:hover {
      text-decoration: none; 
    }


.sign-out {
        min-width: 190px;
        border-radius: 78px;
        background-color: #efefef;
        text-align: center;
        padding: 14px;
        padding-top: 18px;
        margin-right: 2%;
        color: #666;
    }

.dropdown-menu {
    display: none;
}
.top-btn-nav-collapse.sign-in  {
    height: 38px;
    margin-top: 6px;
    padding-top: 11px;
}

#navbar .sign-out a {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color:black;
    text-decoration: none;
    text-transform: uppercase;
    padding-left: 7px;
    padding-right: 2px;
    &:hover {
        color: #ff5959;
    }
}

.top-nav-title a {
    width: 273px;
    height: 40px;
    font-size: 33.2px;
  //  font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: left;
}

.top-nav-link {
    padding:0px 8px;
    display:inline-block;

}
.top-nav-link a {
    font-weight: 400;
    text-transform: uppercase;
    &:hover {
        background: #ff5959;
        color: #333;
    }
}


.top-nav-collapse .top-nav-link a {
    color: #e85151;
    font-weight: 400;
}

.top-nav-collapse .top-nav-link a:hover, .top-nav-collapse .top-nav-link a:focus {
    color: white;
}


.top-nav-button {
    padding:0px 20px;
    display:inline-block;
}

.top-nav a {
    color: white;
    width: 97px;
    height: 22px;


    text-decoration: none;
    font-size: 18.2px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.top-nav a:hover {
    color: black;
    width: 97px;
    height: 22px;
}

.productName {
    font-size: 24px;
    font-weight: 100;
    margin: 8px 0px;
    margin-bottom: 24px;

}

.productName a {
    color: inherit;
}

.darkPage {
    min-height: 800px;
    background: black;
    color: white;
    margin:-24px;
    padding: 24px;
    padding-top: 72px;
}

.pageTitle {
    margin: '6px 0px 25px -2px';
}

.task-status {
    font-size:14px;
        padding-bottom: 18px;
        border-bottom: 1px solid #eee;
        text-align: center;
}


