.marketing {
&--body {
    max-width: 1580px;
    margin: auto;
    background-image: linear-gradient(310deg, #e85151, #ff5959), linear-gradient(#ff5959, #ff5959);
    font-family: ProximaNova, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: 'Montserrat', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; 
}

  &--page-screen {

    opacity: 0;
    transition: opacity .1s linear;
    transition-delay: .25s;
}

&--page-screen-visible {
    opacity: 1;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

&--navbar-header {
    text-align: center;
}

&--navbar {
    padding-top: 84px;
}

&--nav-desktop {
    margin: auto;
    padding: 0px 100px;
}

&--navbar a {
    color: white;
    &:hover  {
        color: #333;
        text-decoration: none;

    }
}

&--navbar-fixed-top.top-nav-collapse &--logo, &--mobile-logo {
    height: 44px;
    position: relative;
    top: 3px;
    left: 1px;
    border-radius:10px;
}

&--navbar-margin {
    margin-top:84px;
}

&--mobile {
    display:none;
}

.mobile-logo {
    top: 6px;
}

&--logo {
    border: none;
}

&--productName {
    display:inline-block;
    font-size:28px;
    font-weight: 400;
    text-transform: none;
    opacity: 1;
    transition: 0.5 ease;
    vertical-align: top;
    padding-top:8px;
    margin-left:10px;

  }
  
#mobile-nav {
    position: relative;
    top: 5px;
    left: 20px;
}

.mobile-logo {
    position: absolute;
    left: 8px;
}

.mobile-product-name {
    display: inline-block;
    color: white;
    font-size: 22px;
    padding-top: 16px;
    text-transform: none;
}

.mobile-brand {
    display:none;
}


@media (max-width: 768px) {
 .mobile-nav, .mobile-brand {
    display: inline-block;
    text-align: center;
}

}

#mobile-nav {
    display: none;
}

&--navbar-brand {
       display: inline-block;
       white-space: nowrap; 
    }

    &--navbar-brand a {
    color: green;
}
&--navbar-brand:focus, &--navbar-brand:hover {
      text-decoration: none; 
    }

      
    &--homepanel {
    width: 100%;
    padding: 0px 15px;
    font-family: ProximaNova, sans-serif;
    text-align: left;
}
&--panel1 {
    padding-bottom: 120px;
    color:white;
    display: inline-flex;
}

&--panel-copy-left {
    margin-left:10%;
    padding-top:40px;
    
}
&--panel-img-bg-right {
    width: 916.3px;
    height: 924.9px;
    display:inline-block;
}

&--panel-h1 {
        font-size: 58px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.07;
        letter-spacing: 1.1px;
}


&--panel-p {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.16;
    letter-spacing: normal;
}

&--sign-in {
        min-width: 170px;
        border-radius: 78px;
        background-color: #ffffff;
        text-align: center;
        padding: 14px;
        padding-top: 18px;
        margin-right: 2%;
        color: #666;
        display: inline-flex;
    }

    &--dropdown-menu {
    display: none;
}
&--top-btn-nav-collapse.sign-in  {
    height: 38px;
    margin-top: 6px;
    padding-top: 11px;
}

&--navbar &--sign-in a {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color:black;
    text-decoration: none;
    text-transform: uppercase;
    padding-left: 7px;
    padding-right: 2px;
    &:hover {
        color: #e85151;
    }
}



&--top-nav-title a {
    width: 273px;
    height: 40px;
    font-size: 33.2px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: left;
}

&--top-nav-link {
    padding:0px 8px;
    display:inline-block;

}
&--top-nav-link a {
    font-weight: 400;
    text-transform: uppercase;
    &:hover {
        background: #aaa;
    }
}


&--top-nav-collapse &--top-nav-link a {
    color: #e85151;
    font-weight: 400;
}

&--top-nav-collapse &--top-nav-link a:hover, &--top-nav-collapse &--top-nav-link a:focus {
    color: white;
}


&--top-nav-button {
    padding:0px 20px;
    display:inline-block;
}

&--top-nav a {
    color: white;
    width: 97px;
    height: 22px;


    text-decoration: none;
    font-size: 18.2px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

&--top-nav a:hover {
    color: black;
    width: 97px;
    height: 22px;
}
}
