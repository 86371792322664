.canvas-field-name {
   // font-family: Roboto, Helvetica, Arial, sans-serif;
    color: #0f1b61;
   // background: rgb(249, 249, 250);
   // font-weight: 900;
    padding: 4px 6px 4px 4px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 16px;
}

.canvas-info {
    color: #dfd0ee; 
    margin-left: 8px;
    font-size:18px;
    &:hover  {
        color: #ff5959;
        transition: 'color 0.5s ease';
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #bbb;
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #888;
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #888;
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #888;
    font-style: italic;
  }