@import './variables';

.App {
  text-align: center;
}

.logo {
    width: 32px;
    padding-top:10px;
}

/*
a {
    color:white;
}

.productName {
    display:inline-block;
    font-size:17px;
    font-weight: 400;
    text-transform: none;
    opacity: 1;
    transition: 0.5 ease;
    vertical-align: top;
    padding-top:13px;
    margin-left:8px;

  }
  */

/* **************************
   standard loading indicator
   *************************** */
   .standard-loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px; // IE hack
      min-height: 70vh;
      .loading-indicator {
        margin: 0;
      }
    }
    .standard-loading-indicator {
      margin: 2em auto;
      position: relative;
      width: 64px;
      height: 64px;
      div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 32px 32px;
        &:after {
          content: " ";
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: grey;
          margin: -3px 0 0 -3px;
        }
        &:nth-child(1) {
          animation-delay: -0.036s;
          &:after {
            top: 50px;
            left: 50px;
          }
        }
        &:nth-child(2) {
          animation-delay: -0.072s;
          &:after {
            top: 54px;
            left: 45px;
          }
        }
        &:nth-child(3) {
          animation-delay: -0.108s;
          &:after {
            top: 57px;
            left: 39px;
          }
        }
        &:nth-child(4) {
          animation-delay: -0.144s;
          &:after {
            top: 58px;
            left: 32px;
          }
        }
        &:nth-child(5) {
          animation-delay: -0.18s;
          &:after {
            top: 57px;
            left: 25px;
          }
        }
        &:nth-child(6) {
          animation-delay: -0.216s;
          &:after {
            top: 54px;
            left: 19px;
          }
        }
        &:nth-child(7) {
          animation-delay: -0.252s;
          &:after {
            top: 50px;
            left: 14px;
          }
        }
        &:nth-child(8) {
          animation-delay: -0.288s;
          &:after {
            top: 45px;
            left: 10px;
          }
        }
      }
    }
  
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .floatright {
      float: right;
  }

   /* **************************
    for TaskList
   *************************** */
   .tl-Box {
       background: white;
       min-height: 300px;
       margin-bottom: 32px;
       margin-left: 2px;
       border: 1px solid #ddd;
       border-radius: 6px;
       padding: 6px;
   }

  /* **************************
    for FormDialogs
   *************************** */
  .fd-errorMessage {
      color: red;
      font-size: 18px;
      margin: 12px 0px;
      padding: 0px 12px;
  }

  .fd-minWidth {
      min-width: 400px;
  }

  .fd-wizardContent {
    display: inline-flex;
  }

  .fd-wizardPanel {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      height: 300px;
      display: inline-block;
      width: 400px;
      padding-top: 32px;
      padding-left:20px;
  }

  .fd-wizardQuestion {
      padding: 4px 20px;
      min-width: 380px;
      max-width: 500px;
      display: inline-block;
  }
  .fd-notWizardQuestion {
    padding: 4px 20px;
    min-width: 80%;
    max-width: 500px;
    display: inline-block;
}
.fd-notWizardSelect {
    padding: 4px 20px;
    min-width: 80%;
    max-width: 500px;
    display: inline-block;
}

  .fd-selectEmpty {
    margin-top: 20px,
  }

  .fd-staticForm {
      /* Firefox */
    height: -moz-calc(100% - 20px);
/* WebKit */
    height: -webkit-calc(100% - 20px);
/* Opera */
    height: -o-calc(100% - 20px);
/* Standard */
    height: calc(100% - 20px);
    overflow: auto;
  }

  /* *************************
     for material ui
     ************************* */

.flex-grow {
    flex-grow: 1
}

/* ***********************
    avatars
    **********************/

.avatar {

    color: #fff;
    &-color0 {
        color: #fff !important;
        background-color: #808 !important;
    }
    &-color1 {
        color: #000 !important;
      background-color:#ff5959 !important;
  }
  &-color2 {
    color: #000 !important;
  background-color: rgb(244, 247, 229) !important;
}
&-color3 {
    color: #000 !important;
  background-color: rgb(148, 220, 241) !important;
}
  &-small {
      width: 28px !important;
      height: 28px !important;
      font-size: 16px !important;
  }
}
/* **********************
    for board designs
    ********************* */
.board-name {
    font-size: 19px;
   // font-weight: 900;
    letter-spacing: 0.2px;
}

.board-subtitle {
    font-style: italic;
    font-size: 14px;
    color: #7f8fa4;
    margin-top: 4px;
}

.board-gadgets {
    float:right;
    color: #7f8fa4;
}

.board-gadget {
    padding: 0px 4px !important;
}
.board-bar {
        margin-top: 16px;
        height: 2px;
        background-color: $bar0;
        margin-bottom: 16px;
}

.board-color0 {
    background-color: $bar0; 
}

.board-color1 {
    background-color: $bar1; 
}
.board-color2 {
    background-color: $bar2; 
}
.board-color3 {
    background-color: $bar3; 
}
/* **********************
   product card: pcard
   ********************** */ 
.pcard {
    position: relative;
    min-height: 250px;
    background: rgba(128,128,128,0.2);
    margin-bottom: 12px;
}

.pcard-image {
    height: 140px;
    background-size: '100% auto !important';
    background-repeat: 'no-repeat !important';
    transition: 'background-size 0.5s ease';
    &:hover  {
        background-size: '110% auto !important';
        transition: 'background-size 0.5s ease';
    }
}

.pcard-bottom {
    padding: 14px 8px;
    border-radius:4px;
    &-table {
        width: 100%;
        font-size:14px;
    }
    &-right {
        float:right;
    }
    &-subtitle {
        font-size: 11px;
        color: #7f8fa4;
        margin: 4px 0px;
    }
    &-button {
        background:white !important;
        width: 68px;
        border-radius: 24px !important;
    }
}

.pcard-tool {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #ccc;
    font-size: 20px;
    text-shadow: 0px 2px #333;
    background: #90858545;
    border-radius: 4px;
    padding: 1px 8px;
    padding-bottom: 3px;
    &:hover {
        color: #ff5959;
        text-decoration: none;
    }
}

.pcard-tool-open-icon {
    transform: rotate(0deg);
    /* Firefox */
	-moz-transition: all 0.25s ease;
	/* WebKit */
	-webkit-transition: all 0.25s ease;
	/* Opera */
	-o-transition: all 0.25s ease;
	/* Standard */
	transition: all 0.25s ease;
}

.pcard-tool-close-icon {
    transform: rotate(180deg);
    /* Firefox */
	-moz-transition: all 1s ease;
	/* WebKit */
	-webkit-transition: all 1s ease;
	/* Opera */
	-o-transition: all 1s ease;
	/* Standard */
	transition: all 1s ease;
}

.pcard-tool-open {
    position: absolute;
    top: 22px;
    right: 8px;
    font-size: 16px;
    line-height: 22px;
    background: white;
    border-radius: 4px;
    text-shadow: initial;
    padding: 12px;
    padding-bottom: 3px;
}

.pcard-tool-option {
     color: rgb(112, 111, 111);
    &:hover {
        color: #ff5959;
        text-decoration: none;
        cursor: pointer;
    }
}
/* **********************
    misc
    ********************* */
.center {
    text-align: center;
}

.white-icon {
    color: gray;
    &:hover {
        color: white;
    }
}

.white-link {
    color: white;
    &:hover {
       text-decoration: underline;
    }
}
