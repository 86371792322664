
.form-page-form-title {
    font-weight: 900;
    font-size: 20px;
    display: inline-block;
    width: 250px;
    text-align: right;
    margin-bottom: 24px;
}

.form-page-form-buttons {
   // float: right;
}

.form-page-form-response {
    display: inline-block;
    max-width: 500px;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 24px;
    margin-left: 24px;
    font-size: 16px;
    font-weight: 100;
    color: #555;
    vertical-align: top;
    margin-top:-6px;
}